<!-- 我的账户 -->
<template>
  <div class="conatiner">
    <div class="header">
      <div class="inner-cont">
        <div class="title">二级商户余额（元）</div>
        <div class="banlance">{{ merchantBanlance }}</div>
        <div class="illustrate">该明细仅供参考，最终以“微信支付商家助手”明细为准</div>
        <div class="withdrawal" @click="onHanldeWithdraw()">提现</div>
      </div>
    </div>
    <div class="list">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="下拉刷新~" loosing-text="下拉刷新~">
        <van-list v-model="loading" :finished="finished" @load="getIncomeList" offset="40">
          <div v-for="(item, index) in dataList" :key=index :data-key=index ref="box" class="item-box">
            <div class="the-header">
              <div class="Detailed_time">
                <div class="time" @click="searchDate(item.month)" v-if="index == 0">{{ item.month }}<span
                    class="icon-shang"></span></div>
                <div class="time" v-else>{{ item.month }}</div>
                <div>支出：￥{{ item.expenditure }} 收入：￥{{ item.income }}</div>
              </div>
            </div>
            <div v-if="item.list.length == 0" class="no-list-data">本月暂无账单数据</div>
            <van-cell v-for="it in item.list" :key="it.index"
              :class="{ 'type4': (((!it.showOrderMoney) && (!it.showPriceSpread)) && (!it.plus)) }">
              <div class="the-icon">
                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210909103940786.png" height="22"
                  width="22" />
              </div>
              <div class="the-cont">
                <div class="the-left">
                  <div class="first-line">
                    <span class="name" :class="{ 'line-through': it.orderCancel }">{{ it.title }}</span>
                  </div>
                  <div class="third-line">
                    <span class="date">操作时间：{{ it.dateTimes }}</span>
                  </div>
                  <div class="second-line">
                    <div class="returnbank" v-if="it.describe">（{{ it.describe }}）</div>
                    <div class="second-div" v-if="it.order_num"></div>
                    <input type="text" readonly :value="it.orderNum" :id="it.orderNum" v-if="it.orderNum"
                      class="order-no ellipsis1" :class="{ 'line-through': it.orderCancel }">
                    <button v-if="it.orderNum" type="button" class="copy-btn" v-clipboard:copy="it.orderNum"
                      v-clipboard:success="onCopy">复制</button>
                  </div>
                </div>
                <div class="the-right">
                  <div class="first-line" :class="{ 'the-through': it.state == 2 }">
                    <span class="money" :style="it.posiAndnega ? 'color:#ff98a0' : ''"
                      :class="{ 'line-through': it.orderCancel }">
                      {{ it.posiAndnega === 1 ? '+' : '-' }}{{ it.money }}
                    </span>
                  </div>
                  <div class="third-line">
                    <span class="state" v-if="it.state == 0">待解冻</span>
                    <span class="state" v-if="it.state == 1">已解冻</span>
                  </div>
                  <div class="second-line" :class="{ 'the-through': it.state == 2 }">
                    <span class="money-type">订单金额：{{ it.orderAmount }}</span>
                  </div>
                </div>
              </div>
            </van-cell>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="blankPage" v-if="loaded && dataList.length === 0">
        <div class="bg-circle">
          <img src="../../assets/images/empty/empty.png">
        </div>
        <span class="title">暂无数据!</span>
      </div>
    </div>
    <div class="layer">
      <van-popup v-model="dateShow" position="bottom" :style="{ height: '34%' }" class="datePop">
        <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate"
          @confirm="selectDate" @cancel="dateShow = false" />
      </van-popup>
    </div>
  </div>
</template>
<script>
import { replaceAppToken } from "../../utils/appUtil"
export default {
  name: '',
  data() {
    return {
      searchBarFixed: false,
      currentDate: new Date(),
      showSelect: false,
      list: [],
      newListItem: [],
      headShow: false,
      headActive: true,
      brandId: 3,
      pageIndex: 0,
      pageSize: 10,
      loading: true, //上拉加载更多 loading
      isLoading: false, //下拉刷新 loading
      finished: false,
      loaded: false,
      dateShow: false,
      canDate: '',
      scrollTop: 0,
      itemTop: {
        offsetTop: 0,
        offsetHeight: 0,
      },
      itemIndex: 0,
      timeStr: '',
      lastTimeStr: '',
      maxDate: new Date(),
      minDate: new Date(2018, 1, 1),
      dataList: [],
      merchantBanlance: 0,//二级商户收益
      isInApp: false,
    }
  },
  created() {
    let { token } = this.$route.query;
    if (token) {
      this.isInApp = true;
      replaceAppToken(token, () => {
        this.init();
      });
    } else {
      this.init()
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    init() {
      this.getAccountBanlance();
      this.getIncomeList();
    },
    searchDate: function (date) {
      this.currentDate = new Date(date.replace(/年/, "/").replace(/月/, "/"));
      this.dateShow = true;
    },
    getAccountBanlance() {
      this.get("/Base/Balance/MyAccountBalance", {}, 3).then(res => {
        if (res.code === 1) {
          this.merchantBanlance = res.response.income;
        }
      })
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
      let itemClientHeight = this.$refs['box'];
      let itemOffsetTop = [];
      for (var i = 0; i < itemClientHeight.length; i++) {
        let boxTop = itemClientHeight[i].offsetTop;
        let boxHeight = itemClientHeight[i].offsetHeight;
        this.itemTop = {
          offsetTop: boxTop,
          offsetHeight: boxHeight
        }
        itemOffsetTop.push(this.itemTop)
      }
      for (var j = 0; j < itemOffsetTop.length; j++) {
        if (scrollTop >= itemOffsetTop[j].offsetTop && scrollTop <= (itemOffsetTop[j].offsetHeight + itemOffsetTop[j].offsetTop)) {
          this.itemIndex = j
        }
      }
    },
    onRefresh() {
      this.loaded = false;
      this.pageIndex = 0;
      this.dataList = [];
      this.lastTimeStr = "";
      setTimeout(() => {
        this.isLoading = false;
        this.getIncomeList();
      }, 100);
    },
    //获取收支列表
    getIncomeList: function (select) {
      this.finished = false;
      this.loading = true;
      this.pageIndex++;
      let secTime = this.currentDate.getFullYear() + '-' + (this.currentDate.getMonth() + 1);
      let lastTimeStr = this.lastTimeStr.replace(/月/g, "").replace(/年/g, "/")
      let formData = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        dateStr: this.canDate,
        timeStr: lastTimeStr,
        searchTime: secTime
      };
      this.post("/Base/Balance/MyAccountDetailList", formData, true, 3).then(res => {
        if (res.code === 1) {
          let listData = res.response;
          if (listData.length > 0) {
            this.lastTimeStr = listData[listData.length - 1].month
          }
          this.dataList = this.dataList.concat(listData);
          if (listData.length < this.pageSize) {
            this.finished = true;
          }
          this.loaded = true;
          this.loading = false;
        }
      })
    },
    onCopy() {
      this.$toast("复制成功");
    },
    //数组去重
    newArrFun: function (arr) {
      let arrL = [];
      for (var k = 0; k < arr.length; k++) {
        arrL = arr[k].list;
        for (var i = 0; i < arrL.length; i++) {
          for (var j = i + 1; j < arrL.length; j++) {
            if (arrL[i].accountId == arrL[j].accountId) {
              //如果第一个等于第二个，splice方法删除第二个
              arrL.splice(j, 1);
              j--;
            }
          }

        }
      }
      return arrL;
    },
    selectDate: function (e) {
      this.pageIndex = 0;
      this.dataList = [];
      this.getIncomeList();
      this.dateShow = false;
    },
    getTimeStr: function (value) {
      let year = value.getFullYear(), mouth = value.getMonth() + 1;
      let timeStr = year + "年" + this.dealLessTen(mouth) + "月";
      return timeStr;
    },
    //处理日期.小于10 前面加 0
    dealLessTen: function (num) {
      if (num < 10) {
        return '0' + num
      }
      return num;
    },
    onHanldeWithdraw() {
      this.$dialog.alert({ title: "提示", message: "请搜索“微信支付商户助手”小程序，点击资金 管理--基本账户--提现。" })
    }
  }
}
</script>
<style lang='scss' scoped>
.conatiner {
  height: 100%;
  text-align: left;
  background-color: #F8F8F8;

  .header {
    height: 120px;
    position: relative;
    padding: 25px 15px 0;
    background-color: #FFB5BB;

    .inner-cont {
      color: #000;
      padding: 15px;
      margin-top: 34px;
      position: relative;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba($color: #55DCF9, $alpha: 0.2);
      background-color: #fff;

      .title {
        font-size: 10px;
      }

      .banlance {
        font-size: 30px;
        font-weight: bold;
        padding: 10px 0;
      }

      .illustrate {
        color: #666;
        font-size: 11px;
      }

      .withdrawal {
        position: absolute;
        top: calc(50% - 11px);
        right: 0;
        color: #fff;
        padding: 4px 10px 4px 13px;
        border-radius: 26px 0 0 26px;
        background-color: #FFB5BB;
      }
    }
  }

  .showBlock {
    display: block;
  }

  .the-header {
    // position: fixed;
    left: 0;
    top: 0;
    width: calc(100% - 30px);
    padding: 0 15px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    z-index: 99;

    .Detailed_time {
      .time {
        font-size: 14px;
        color: #666666;
        display: inline-block;

        >span {
          margin-left: 5px;
          color: #666666;
          display: inline-block;
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transition: transform .5s;
        }
      }

      >div {
        color: #999999;
        font-size: 12px;
      }
    }

    .the-search {
      font-size: 12px;
      color: #9f9f9f;
      display: flex;
      align-items: center;
      border: 1px solid #e5e5e5;
      padding: 4px 13px;
      border-radius: 15px;

      .icon-search {
        font-size: 14px;
        margin-right: 7px;

        &:before {
          content: '\e9cb';
        }
      }
    }
  }

  .notData {
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    border-top: 1px solid #EEEEEE;
    color: #999999;
  }

  .list {
    margin-top: 50px;
    height: calc(100% - 200px);
    overflow-y: auto;



    .van-cell {
      padding: 0;

      .van-cell__value {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        >.the-cont {
          padding: 15px 0;
          width: calc(100% - 35px);
          display: flex;
          justify-content: space-between;

          .the-left {
            .first-line {
              display: flex;
              align-items: flex-start;

              .name,
              .order-no {
                font-size: 12px;
                color: #666666;
                font-weight: bold;
              }

            }

            .second-line {
              font-size: 12px;
              color: #666666;
              position: relative;

              .order-no {
                width: 150px;
                border: 0;
                margin: 0;
                padding: 0;
              }
            }

            .third-line {
              display: flex;
              justify-content: space-between;
              max-width: 200px;

              .date {
                font-size: 11px;
                color: #999999;
                margin-right: 10px;
              }

              .state {
                white-space: nowrap;
                height: 24px;
                line-height: 24px;
                font-size: 11px;
                color: #11c3c4;
                background-color: #f2f2f2;
                padding: 0 7px;
              }


            }

          }

          .the-right {
            text-align: right;

            .first-line {
              margin-left: 5px;
              color: #ff98a0;
              white-space: nowrap;
            }

            .second-line {
              display: inline-block;
              margin-left: 5px;
              color: #666666;
              white-space: nowrap;
            }

            .third-line {
              .state {
                color: #FFB5BB;
                font-size: 11px;
                width: 50px;
                height: 15px;
                text-align: center;
                line-height: 15px;
                border-radius: 15px;
                display: block;
                float: right;
                margin-top: 5px;
              }

              .failed {
                color: #666666;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .van-icon-question {
                  margin-left: 5px;
                }
              }
            }

            .money-type {
              color: #999999;
              font-size: 11px;
            }

            .money {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }

        .the-icon {
          width: 22px;
          padding: 15px 0;

          img {
            margin-top: 5px;
          }
        }

      }
    }

    .type4 {
      .the-left {
        .first-line {
          .order-no {
            max-width: 30%;
          }
        }
      }

      .the-right {
        .first-line {
          .money {
            color: #666666;
          }
        }
      }

    }
  }

  .layer {
    z-index: 999;

    .select-type {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      overflow: hidden;
      z-index: 100;

      .the-type {
        padding: 1px 12px 25px 12px;
        border-bottom: solid 1px #f2f2f2;

        .type-flex {
          display: flex;
          flex-wrap: wrap;
        }

        span {
          font-size: 12px;
          color: #666666;
          background-color: #f1f1f1;
          margin-right: 10px;
          line-height: 27px;
          -webkit-border-radius: 13px;
          -moz-border-radius: 13px;
          border-radius: 13px;
          padding: 0 4%;
          text-align: center;
          margin-top: 20px;

          &.selected {
            background-color: #fff5f6;
            height: 25px;
            line-height: 25px;
            color: #ff98a0;
            padding-left: calc(4% - 1px);
            padding-right: calc(4% - 1px);
            border: solid 1px #ff98a0;
          }
        }

        &.second-line {
          display: flex;
          padding: 17px 12px 22px 12px;
          justify-content: flex-start;
          border-top: solid 10px #f2f2f2;

          span {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: calc(68% / 3 - 64px);
          }
        }
      }

      .the-btn {
        height: 44px;
        display: flex;

        div {
          width: 50%;
          height: 100%;
          text-align: center;
          line-height: 44px;
          font-size: 14px;

          &:first-child {
            color: #333333;
          }

          &:last-child {
            color: #fff;
            background: #ff98a0;
          }
        }
      }
    }
  }

  .blankPage {
    text-align: center;
    padding: 50px 0;

    .bg-circle {

      img {
        width: 100px;
      }
    }

    .title {
      padding-top: 10px;
    }
  }

  .payment-failed {
    width: 300px;
    padding: 20px 15px;
    text-align: center;
    overflow: inherit;
    border-radius: 6px;

    .van-icon-clear {
      color: #FFFFFF;
      font-size: 22px;
      position: absolute;
      right: -5px;
      padding: 5px;
      top: -35px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .error-message {
      font-size: 14px;
      text-align: left;
      max-height: 200px;
      overflow-y: auto;
    }

    .serve-info {
      padding: 10px 0;
      font-size: 0;

      img {
        width: 130px;
      }
    }

    .scan-tips {
      color: #666666;
    }
  }

  .van-popup--center {
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
  }

  .line-through {
    text-decoration: line-through;
  }

  .the-through {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 12px;
      height: 1px;
      background-color: #333;
    }
  }

  .icon-shang:before {
    content: "\e93f";
  }

  .van-picker__cancel {
    color: #999999;
    font-size: 12px;
  }

  .van-picker__confirm {
    color: #ff98a0;
    font-size: 12px;
  }

  .datePop {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .copy-btn {
    background: #FFF9FA;
    height: 20px;
    width: 40px;
    line-height: 20px;
    color: #FF98A0;
    border: 0;
    padding: 0;
    font-size: 12px;
    border-radius: 10px;
  }

  .second-div {
    position: absolute;
    width: 150px;
    height: 24px;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .returnRed {
    width: auto !important;
    margin-right: 17px;
  }

  .returnbank {
    color: #ff98a0;
  }

  .no-list-data {
    color: #999999;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
}
</style>