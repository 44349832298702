import { render, staticRenderFns } from "./AccountDetails.vue?vue&type=template&id=a8b4c944&scoped=true&"
import script from "./AccountDetails.vue?vue&type=script&lang=js&"
export * from "./AccountDetails.vue?vue&type=script&lang=js&"
import style0 from "./AccountDetails.vue?vue&type=style&index=0&id=a8b4c944&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b4c944",
  null
  
)

export default component.exports